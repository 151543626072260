/* eslint no-undef: 0 */ // --> OFF

import "../../scripts/loading-bar.js";
import "../../css/loading-bar.css";
import { useEffect, useState, useCallback } from "react";
import _uniqueId from "lodash/uniqueId";

const LiquidGauge = (props) => {
  const id = _uniqueId("liquidGauge-");
  const value = Math.round(props.percentageLogged * 10) / 10;
  const color = props.overall ? "#19c200" : "#1f77b4";
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  let bar;

  useEffect(() => {
    if (bar === undefined) {
      bar = new ldBar("#" + id, {
        preset: "bubble",
        fill: `data:ldbar/res,bubble(${color},#fff,25,3)`,
        "pattern-size": "100",
      });
    }
    bar.set(value);
  }, [props.percentageLogged]);

  return (
    <>
      <div id={id} className="ldBar label-center" style={{ top: "10px", left: "56px", width: "58%", height: "58%" }} />
    </>
  );
};

export default LiquidGauge;
