import { NavLink } from "react-router-dom";
import { FC, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { useStyles } from "../hooks/useStyles";
import { useGet } from "../hooks/useApi";
import { getDashboards } from "../api/endpoints";
import DashboardConfiguration from "../models/DashboardConfiguration";
import ConfirmDashboardDelete from "./ConfirmDashboardDelete";
import { DeleteForever } from "@material-ui/icons";

interface DashboardConfigurationsTableProps {
  linkTo: string;
  showAgents?: boolean;
}

const DashboardConfigurationsTable: FC<DashboardConfigurationsTableProps> = ({ linkTo, showAgents }) => {
  const classes = useStyles();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [dashboardIdToDelete, setDashboardIdToDelete] = useState<number | undefined>(undefined);
  const [dashboardConfigurations, dashboardConfigurationsLoading, refreshDashboardConfigurations] = useGet<
    DashboardConfiguration[]
  >(getDashboards());

  const handleDeleteDialog = () => {
    if (deleteOpen) setDeleteOpen(false);
    else setDeleteOpen(true);
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.margin}>
        <Table size={showAgents ? "small" : "medium"}>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell>Department</TableCell>
              {showAgents && (
                <>
                  <TableCell>Agents</TableCell>
                  <TableCell>Actions</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          {dashboardConfigurationsLoading || dashboardConfigurations === undefined ? (
            <caption style={{ textAlign: "center" }}>
              <CircularProgress />
            </caption>
          ) : (
            <TableBody>
              {dashboardConfigurations.map((dashboardConfiguration) => (
                <TableRow key={dashboardConfiguration.dashboard.id}>
                  <TableCell>
                    <NavLink
                      exact
                      to={`/${linkTo}/${dashboardConfiguration.dashboard.id}`}
                      className={classes.linkStyle}
                    >
                      {dashboardConfiguration.dashboard.name}
                    </NavLink>
                  </TableCell>
                  {showAgents && (
                    <>
                      <TableCell>{dashboardConfiguration.agentConfigurations!.length}</TableCell>
                      <TableCell>
                        <Tooltip title="Delete">
                          <IconButton
                            color="secondary"
                            onClick={() => {
                              setDashboardIdToDelete(dashboardConfiguration.dashboard.id!);
                              handleDeleteDialog();
                            }}
                          >
                            <DeleteForever />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <ConfirmDashboardDelete
        dashboardId={dashboardIdToDelete}
        open={deleteOpen}
        handleDialog={handleDeleteDialog}
        refreshTable={refreshDashboardConfigurations}
      />
    </>
  );
};

export default DashboardConfigurationsTable;
