import { Paper, Typography, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3, 2),
  },
}));

const LoggingIn = () => {
  const classes = useStyles();

  return (
    <Paper>
      <Typography className={classes.paper}>Logging in...</Typography>
    </Paper>
  );
};

export default LoggingIn;
