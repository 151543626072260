import { API_URI } from "../environment-variables";

const getDashboards = (): string => `${API_URI}/dashboards`;
const getDashboardById = (id: number): string => `${API_URI}/dashboards/${id}`;
const getTimesheetById = (id: number): string => `${API_URI}/timesheets/${id}`;
const getAgents = (): string => `${API_URI}/agents`;

const newDashboard = (): string => `${API_URI}/dashboards`;

const deleteDashboardById = (id: number): string => `${API_URI}/dashboards/${id}`;

const validateDashboard = (): string => `${API_URI}/ValidateDashboardName`;

export {
  getDashboards,
  getDashboardById,
  getTimesheetById,
  getAgents,
  newDashboard,
  deleteDashboardById,
  validateDashboard,
};
