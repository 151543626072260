import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useStyles } from "../hooks/useStyles";
import { IconButton } from "@material-ui/core";
import LightModeIcon from "@material-ui/icons/WbIncandescent";
import DarkModeIcon from "@material-ui/icons/WbIncandescentOutlined";

interface NavbarProps {
  darkMode: boolean;
  toggleTheme(): void;
}

const Navbar: FC<NavbarProps> = ({ darkMode, toggleTheme }) => {
  const activeStyle = { color: darkMode ? "#fff" : "#292b2c" };
  const classes = useStyles();

  return (
    <nav style={{ marginTop: "2vh", marginBottom: "2vh" }}>
      <NavLink exact to="/timesheets" className={classes.linkStyle} activeStyle={activeStyle}>
        Timesheets
      </NavLink>
      {" | "}
      <NavLink exact to="/dashboards" className={classes.linkStyle} activeStyle={activeStyle}>
        Dashboards
      </NavLink>
      <IconButton color="inherit" aria-label="darkMode" onClick={toggleTheme}>
        {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
      </IconButton>
    </nav>
  );
};

export default Navbar;
