/* eslint no-undef: 0 */ // --> OFF

import "../../scripts/loading-bar.js";
import "../../css/loading-bar.css";
import { useEffect } from "react";
import _uniqueId from "lodash/uniqueId";

const FanGauge = (props) => {
  const id = _uniqueId("fanGauge-");
  const value = Math.round(props.percentageLogged * 10) / 10;
  const color = props.overall ? "#19c200" : "#1f77b4";
  let bar;

  useEffect(() => {
    if (bar === undefined) {
      bar = new ldBar("#" + id, {
        preset: "fan",
        stroke: color,
        "stroke-width": 10,
        "baseline.stroke-width": 10,
      });
    }
    bar.set(value);
  }, [props.percentageLogged]);

  return (
    <>
      <div id={id} className="ldBar label-center" style={{ top: "2px", left: "35px", width: "75%", height: "75%" }} />
    </>
  );
};

export default FanGauge;
