import { FC } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { useStyles } from "../../hooks/useStyles";
import AgentConfiguration from "../../models/AgentConfiguration";
import { Delete } from "@material-ui/icons";

interface AgentConfigurationsTableProps {
  agentConfigurations: AgentConfiguration[];
  removeAgentConfiguration: (email: string) => void;
}

const AgentConfigurationsTable: FC<AgentConfigurationsTableProps> = ({
  agentConfigurations,
  removeAgentConfiguration,
}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.margin}>
      <Table size="small">
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell>Agent</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Hours</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agentConfigurations.map((agentConfiguration) => (
            <TableRow key={agentConfiguration.email}>
              <TableCell>{agentConfiguration.name}</TableCell>
              <TableCell>{agentConfiguration.email}</TableCell>
              <TableCell>{agentConfiguration.hours}</TableCell>
              <TableCell>
                <Tooltip title="Remove">
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      removeAgentConfiguration(agentConfiguration.email);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AgentConfigurationsTable;
