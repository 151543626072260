import { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { usePostWith } from "../hooks/useApi";
import { useStyles } from "../hooks/useStyles";
import { newDashboard, validateDashboard } from "../api/endpoints";
import DashboardConfiguration from "../models/DashboardConfiguration";
import DashboardConfigurationResponse from "../models/DashboardConfigurationResponse";
import DashboardConfigurationsTable from "../components/DashboardConfigurationsTable";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import ValidDashboard from "../models/ValidDashboard";
import DashboardValidation from "../models/DashboardValidation";
import debounce from "lodash.debounce";

const DashboardsMenuPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [openCreateDialog, setOpenCreateDashboardDialog] = useState(false);
  const [
    dashboardConfiguration,
    setDashboardConfiguration,
    postDashboardConfiguration,
    postResponse,
    isLoading,
  ] = usePostWith<DashboardConfiguration, DashboardConfigurationResponse>(newDashboard(), {
    dashboard: { name: "" },
    agentConfigurations: [],
  });
  const [, , postValidateSpfConfig, validateResponse, validateLoading] = usePostWith<
    DashboardValidation,
    ValidDashboard
  >(validateDashboard(), {
    name: "",
  });
  const [dashboardNameTaken, setDashboardNameTaken] = useState(false);
  const [dashboardNameChanging, setDashboardNameChanging] = useState(false);

  const debouncedValidate = useRef(
    debounce(
      (name) =>
        postValidateSpfConfig({
          name: name,
        }),
      700,
    ),
  ).current;

  useEffect(() => {
    if (postResponse !== undefined) {
      history.push(`dashboards/${postResponse.dashboardId}`);
    }
  }, [postResponse, history]);

  useEffect(() => {
    if (validateResponse !== undefined && !validateLoading) {
      if (!validateResponse.valid) {
        setDashboardNameTaken(true);
      } else {
        setDashboardNameTaken(false);
      }
      setDashboardNameChanging(false);
    }
  }, [validateLoading, validateResponse]);

  return (
    <>
      <h1>Manage Dashboards</h1>
      <h4 className={classes.margin}>Add, edit or delete dashboards.</h4>

      <DashboardConfigurationsTable linkTo="dashboards" showAgents />

      <Button
        variant="contained"
        className={(classes.largeMargin, classes.saveButton)}
        onClick={() => setOpenCreateDashboardDialog(true)}
      >
        Create Dashboard
      </Button>

      <Dialog
        open={openCreateDialog}
        onClose={() => {
          setOpenCreateDashboardDialog(false);
          setDashboardConfiguration({
            dashboard: { name: "" },
            agentConfigurations: [],
          });
        }}
      >
        <DialogTitle>Create Dashboard</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            label="Name"
            value={dashboardConfiguration.dashboard.name}
            error={dashboardNameTaken}
            helperText={dashboardNameTaken ? "Dashboard name already in use." : ""}
            onChange={(e) => {
              setDashboardNameChanging(true);
              setDashboardConfiguration({
                dashboard: { name: e.target.value },
                agentConfigurations: [],
              });
              debouncedValidate(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={dashboardConfiguration.dashboard.name === "" || dashboardNameChanging || dashboardNameTaken}
            className={classes.margin}
            onClick={() => postDashboardConfiguration()}
          >
            {!isLoading ? "Create" : <CircularProgress />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DashboardsMenuPage;
