import { ChangeEvent, FC, useState, Fragment, useEffect, useRef } from "react";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useStyles } from "../hooks/useStyles";
import { useGet, useGetAndUpdate, usePostWith } from "../hooks/useApi";
import { getDashboardById, getAgents, validateDashboard } from "../api/endpoints";
import DashboardConfiguration from "../models/DashboardConfiguration";
import AgentConfigurationsTable from "../components/dashboard/AgentConfigurationsTable";
import {
  CircularProgress,
  Container,
  TextField,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Agent from "../models/Agent";
import AgentConfiguration from "../models/AgentConfiguration";
import { useSnackbar } from "notistack";
import DashboardValidation from "../models/DashboardValidation";
import ValidDashboard from "../models/ValidDashboard";
import debounce from "lodash.debounce";

const DashboardPage: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  const { id }: any = useParams();
  const [changesMade, setChangesMade] = useState(false);
  const [timezoneOffsetDirection, setTimezoneOffsetDirection] = useState("ahead");
  const [openAddAgentDialog, setOpenAddAgentDialog] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const [agentConfigurationToAdd, setAgentConfigurationToAdd] = useState<AgentConfiguration>({
    dashboardId: id,
    email: "",
    hours: 1,
    name: "",
  });
  const min = timezoneOffsetDirection === "ahead" ? 0 : -24;
  const max = timezoneOffsetDirection === "ahead" ? 24 : 0;
  const [
    dashboardConfiguration,
    setDashboardConfiguration,
    putDashboardConfiguration,
    dashboardConfigurationLoading,
  ] = useGetAndUpdate<DashboardConfiguration>(getDashboardById(id));
  const [agents, agentsLoading] = useGet<Agent[]>(getAgents());
  const [, , postValidateSpfConfig, validateResponse, validateLoading] = usePostWith<
    DashboardValidation,
    ValidDashboard
  >(validateDashboard(), {
    name: "",
  });
  const [dashboardNameTaken, setDashboardNameTaken] = useState(false);
  const [dashboardNameChanging, setDashboardNameChanging] = useState(false);

  const handleNameChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDashboardNameChanging(true);
    setDashboardConfiguration({
      dashboard: { ...dashboardConfiguration?.dashboard!, name: e.target.value },
      agentConfigurations: dashboardConfiguration?.agentConfigurations!,
    });
    debouncedValidate(e.target.value);
    setChangesMade(true);
  };

  const handleTimezoneOffsetChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDashboardConfiguration({
      dashboard: { ...dashboardConfiguration?.dashboard!, timezoneOffset: Number(e.target.value) },
      agentConfigurations: dashboardConfiguration?.agentConfigurations!,
    });
    setChangesMade(true);
  };

  const handleTimezoneOffsetDirectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "ahead") {
      setDashboardConfiguration({
        dashboard: {
          ...dashboardConfiguration?.dashboard!,
          timezoneOffset: Math.abs(dashboardConfiguration?.dashboard!.timezoneOffset!),
        },
        agentConfigurations: dashboardConfiguration?.agentConfigurations!,
      });
    } else {
      setDashboardConfiguration({
        dashboard: {
          ...dashboardConfiguration?.dashboard!,
          timezoneOffset: -Math.abs(dashboardConfiguration?.dashboard!.timezoneOffset!),
        },
        agentConfigurations: dashboardConfiguration?.agentConfigurations!,
      });
    }
    setTimezoneOffsetDirection(event.target.value);
    setChangesMade(true);
  };

  const removeAgentConfiguration = (email: string) => {
    setDashboardConfiguration({
      dashboard: dashboardConfiguration?.dashboard!,
      agentConfigurations: dashboardConfiguration?.agentConfigurations!.filter((ac) => ac.email !== email),
    });
    setChangesMade(true);
  };

  const handleAddAgent = () => {
    setDashboardConfiguration({
      dashboard: dashboardConfiguration?.dashboard!,
      agentConfigurations: [...dashboardConfiguration?.agentConfigurations!, agentConfigurationToAdd],
    });
    setAgentConfigurationToAdd({ ...agentConfigurationToAdd, hours: 1 });
    setSelectedAgent(null);
    setChangesMade(true);
  };

  const debouncedValidate = useRef(
    debounce(
      (name) =>
        postValidateSpfConfig({
          dashboardId: id,
          name: name,
        }),
      700,
    ),
  ).current;

  useEffect(() => {
    if (dashboardConfiguration !== undefined) {
      if (dashboardConfiguration.dashboard!.timezoneOffset! < 0) {
        setTimezoneOffsetDirection("behind");
      }
    }
  }, [dashboardConfiguration]);

  useEffect(() => {
    if (validateResponse !== undefined && !validateLoading) {
      if (!validateResponse.valid) {
        setDashboardNameTaken(true);
      } else {
        setDashboardNameTaken(false);
      }
      setDashboardNameChanging(false);
    }
  }, [validateLoading, validateResponse]);

  return (
    <Container maxWidth="xl">
      <Prompt when={changesMade} message="Are you sure you want to leave without saving? Your changes will be lost." />
      {dashboardConfiguration !== undefined && !dashboardConfigurationLoading ? (
        <>
          <h1>Edit {dashboardConfiguration.dashboard.name}</h1>

          <TextField
            className={classes.largeMargin}
            label="Name"
            variant="outlined"
            value={dashboardConfiguration.dashboard.name}
            error={dashboardNameTaken}
            helperText={dashboardNameTaken ? "Dashboard name already in use." : ""}
            onChange={handleNameChange}
            fullWidth
          />

          <TextField
            className={classes.margin}
            label="Timezone Offset (hours from GMT)"
            variant="outlined"
            type="number"
            error={
              dashboardConfiguration?.dashboard!.timezoneOffset! > max ||
              dashboardConfiguration?.dashboard!.timezoneOffset! < min
            }
            InputProps={{
              inputProps: {
                min: min,
                max: max,
              },
            }}
            value={dashboardConfiguration.dashboard.timezoneOffset}
            onChange={handleTimezoneOffsetChange}
            style={{ width: "50%" }}
          />

          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              value={timezoneOffsetDirection}
              onChange={handleTimezoneOffsetDirectionChange}
            >
              <FormControlLabel
                value="ahead"
                control={<Radio color="primary" />}
                label="Ahead"
                labelPlacement="start"
              />
              <FormControlLabel
                value="behind"
                control={<Radio color="primary" />}
                label="Behind"
                labelPlacement="start"
              />
            </RadioGroup>
          </FormControl>

          <AgentConfigurationsTable
            agentConfigurations={dashboardConfiguration.agentConfigurations!}
            removeAgentConfiguration={removeAgentConfiguration}
          />

          <Grid container spacing={3}>
            <Grid item xs={4} sm={2}>
              <Autocomplete
                options={agents ?? []}
                loading={agents === undefined || agentsLoading}
                getOptionLabel={(option) => option.name}
                value={selectedAgent}
                onChange={(_event: any, newValue: Agent | null) => {
                  setSelectedAgent(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Agent"
                    margin="normal"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {agents === undefined || agentsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                variant="contained"
                className={classes.largeMargin}
                style={{ top: "1vh" }}
                disabled={selectedAgent === null}
                onClick={() => {
                  setOpenAddAgentDialog(true);
                  setAgentConfigurationToAdd({
                    ...agentConfigurationToAdd,
                    email: selectedAgent?.email!,
                    name: selectedAgent?.name!,
                  });
                }}
              >
                Add Agent
              </Button>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            className={(classes.margin, classes.saveButton)}
            disabled={
              dashboardConfiguration?.dashboard!.timezoneOffset! > max ||
              dashboardConfiguration?.dashboard!.timezoneOffset! < min ||
              !changesMade ||
              dashboardNameTaken ||
              dashboardNameChanging
            }
            onClick={async () => {
              setChangesMade(false);
              enqueueSnackbar("Saving...", { variant: "default" });
              var success = await putDashboardConfiguration();
              console.log(success);
              if (success) {
                console.log("save");
                enqueueSnackbar("Saved!", { variant: "success" });
              } else {
                console.log("not save");
                enqueueSnackbar("Something went wrong...", { variant: "error" });
              }
              history.push("/dashboards");
            }}
          >
            {!dashboardConfigurationLoading ? "Save" : <CircularProgress />}
          </Button>

          <Dialog
            open={openAddAgentDialog}
            onClose={() => {
              setOpenAddAgentDialog(!setOpenAddAgentDialog);
              setAgentConfigurationToAdd({ ...agentConfigurationToAdd, hours: 0 });
            }}
          >
            <DialogTitle>{agentConfigurationToAdd?.name}</DialogTitle>
            <DialogContent>
              <TextField
                variant="outlined"
                label="Hours"
                type="number"
                value={agentConfigurationToAdd?.hours}
                error={agentConfigurationToAdd?.hours > 24 || agentConfigurationToAdd?.hours < 1}
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 24,
                  },
                }}
                onChange={(e) =>
                  setAgentConfigurationToAdd({ ...agentConfigurationToAdd, hours: Number(e.target.value) })
                }
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                className={classes.margin}
                disabled={agentConfigurationToAdd?.hours > 24 || agentConfigurationToAdd?.hours < 1}
                onClick={() => {
                  handleAddAgent();
                  setOpenAddAgentDialog(false);
                }}
              >
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      )}
    </Container>
  );
};

export default DashboardPage;
