import { CircularProgress, Switch, Typography, Grid } from "@material-ui/core";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getTimesheetById } from "../api/endpoints";
import { useGet } from "../hooks/useApi";
import usePersistedState from "../hooks/usePersistedState";
import DashboardTimesheet from "../models/DashboardTimesheet";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AgentGauges from "../components/timesheet/AgentGauges";
import { useStyles } from "../hooks/useStyles";

interface TimesheetPageProps {}

const TimesheetPage: FC<TimesheetPageProps> = () => {
  const { id }: any = useParams();
  const classes = useStyles();
  const [useWashingMachines, setUseWashingMachines] = usePersistedState(false, "useWashingMachines");
  const [showHours, setShowHours] = usePersistedState(false, "showHours");
  const [dashboardTimesheet, , refreshTimesheets] = useGet<DashboardTimesheet>(getTimesheetById(id));

  // Refreshes timesheet data every 5 minutes
  useEffect(() => {
    setInterval(() => refreshTimesheets(), 5 * 60 * 1000);
  }, []);

  return (
    <>
      {dashboardTimesheet === undefined ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Grid container justify="flex-end" style={{ marginTop: "-4%", marginBottom: "3%", paddingRight: "30px" }}>
            <Grid item style={{ display: "inline-flex" }}>
              <Typography className={classes.margin}>
                Showing timesheets for {dashboardTimesheet.dashboard.dateShowing} |{" "}
              </Typography>
              <FormControlLabel
                control={
                  <Switch checked={useWashingMachines} onChange={() => setUseWashingMachines(!useWashingMachines)} />
                }
                label="Washing Machines"
                labelPlacement="start"
              />
              <FormControlLabel
                control={<Switch checked={showHours} onChange={() => setShowHours(!showHours)} />}
                label="Show Hours"
                labelPlacement="start"
              />
            </Grid>
          </Grid>
          <AgentGauges
            showHours={showHours}
            washingMachines={useWashingMachines}
            agentSummaries={dashboardTimesheet.agentSummaries}
          />
        </>
      )}
    </>
  );
};

export default TimesheetPage;
