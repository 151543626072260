declare global {
  interface Window {
    __timesheetingdashboards_env: {
      API_URI: string;
      AUTHORITY: string;
      TENANT_ID: string;
      CLIENT_ID: string;
      REDIRECT_URI: string;
    };
  }
}

const API_URI = window.__timesheetingdashboards_env.API_URI;
const AUTHORITY = window.__timesheetingdashboards_env.AUTHORITY;
const TENANT_ID = window.__timesheetingdashboards_env.TENANT_ID;
const CLIENT_ID = window.__timesheetingdashboards_env.CLIENT_ID;
const REDIRECT_URI = window.__timesheetingdashboards_env.REDIRECT_URI;

export { API_URI, AUTHORITY, TENANT_ID, CLIENT_ID, REDIRECT_URI };
