import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      tableHeader: {
        "& th": {
          fontWeight: "bold",
        },
      },
      linkStyle: {
        color: "#007bff",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
      },
      margin: {
        marginTop: "1vh",
        marginBottom: "1vh",
      },
      largeMargin: {
        marginTop: "2vh",
        marginBottom: "2vh",
      },
      saveButton: {
        color: theme.palette.getContrastText("#007bff"),
        backgroundColor: "#007bff",
        "&:hover": {
          backgroundColor: "#0055b0",
        },
        textTransform: "none",
      },
    }),
  { index: 1 },
);
