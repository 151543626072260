import { FC } from "react";
import FanGauge from "./FanGauge";
import LiquidGauge from "./LiquidGauge";

export interface AgentGaugeProps {
  overall?: boolean;
  showHours: boolean;
  washingMachines: boolean;
  agentName: string;
  percentageLogged: number;
  hoursLogged?: number;
}

const AgentGauge: FC<AgentGaugeProps> = ({
  overall,
  showHours,
  washingMachines,
  agentName,
  percentageLogged,
  hoursLogged,
}) => {
  return (
    <div style={{ width: "260px", float: "left", marginBottom: "3vh" }}>
      {agentName.split(" ").map((part) => (
        <h2 key={agentName + "-" + part} style={{ textAlign: "center", marginBottom: "0px" }}>
          {part}
        </h2>
      ))}

      {washingMachines ? (
        <LiquidGauge overall={overall} percentageLogged={percentageLogged} />
      ) : (
        <FanGauge overall={overall} percentageLogged={percentageLogged} />
      )}

      {showHours && hoursLogged && (
        <div style={{ textAlign: "center", marginTop: "10px" }}>Hours logged: {hoursLogged}</div>
      )}
    </div>
  );
};

export default AgentGauge;
