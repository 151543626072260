import { FC } from "react";
import AgentSummary from "../../models/AgentSummary";
import AgentGauge from "./AgentGauge";

interface AgentGaugesProps {
  showHours: boolean;
  washingMachines: boolean;
  agentSummaries: AgentSummary[];
}

const AgentGauges: FC<AgentGaugesProps> = ({ showHours, washingMachines, agentSummaries }) => {
  let total = 0;
  agentSummaries.map((agentSummary) => (total += agentSummary.percentageLogged));
  const avg = total / agentSummaries.length;

  return (
    <>
      <AgentGauge
        overall
        showHours={showHours}
        washingMachines={washingMachines}
        agentName="Overall Average"
        percentageLogged={avg}
      />
      {agentSummaries.map((agentSummary) => (
        <AgentGauge
          key={agentSummary.email}
          showHours={showHours}
          washingMachines={washingMachines}
          agentName={agentSummary.name}
          percentageLogged={agentSummary.percentageLogged}
          hoursLogged={agentSummary.hoursLogged}
        />
      ))}
    </>
  );
};

export default AgentGauges;
