import { FC } from "react";
import { Container, CssBaseline } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Navbar from "./components/Navbar";
import TimesheetPage from "./pages/TimesheetPage";
import DashboardPage from "./pages/DashboardPage";
import TimesheetsMenuPage from "./pages/TimesheetsMenuPage";
import DashboardsMenuPage from "./pages/DashboardsMenuPage";
import { Route, Switch, Redirect } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { AuthProvider } from "./services/authentication";
import { BrowserRouter as Router } from "react-router-dom";
import PageNotFound from "./pages/PageNotFound";
import usePersistedState from "./hooks/usePersistedState";

const App: FC = () => {
  const [darkMode, setDarkMode] = usePersistedState(false, "dark-mode");
  const palletType = darkMode ? "dark" : "light";

  const appTheme = createMuiTheme({
    palette: {
      type: palletType,
    },
  });

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  return (
    <AuthProvider>
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={appTheme}>
          <CssBaseline />
          <Router>
            <Container maxWidth="xl">
              <Navbar darkMode={darkMode} toggleTheme={toggleTheme} />
              <Switch>
                <Route exact path="/">
                  <Redirect to="/timesheets" />
                </Route>
                <Route path="/timesheets/:id">
                  <TimesheetPage />
                </Route>
                <Route path="/timesheets">
                  <TimesheetsMenuPage />
                </Route>
                <Route path="/dashboards/:id">
                  <DashboardPage />
                </Route>
                <Route path="/dashboards">
                  <DashboardsMenuPage />
                </Route>
                <Route>
                  <PageNotFound />
                </Route>
              </Switch>
            </Container>
          </Router>
        </ThemeProvider>
      </SnackbarProvider>
    </AuthProvider>
  );
};

export default App;
